import React, { useEffect, useMemo } from 'react';
import { getPage, usePageConfig } from 'queries/creatorPage';
import useUserMetadata from 'hooks/useUserMetadata';
import { hasSubscription as hasSubscriptionFn } from 'services/metadata';
import { QueryClient } from 'react-query';
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { dehydrate } from 'react-query/hydration';
import dynamic from 'next/dynamic';
import { UserMetaDataResponse } from '@solin-fitness/types';
import Loading from 'components/Loading/Loading';
import ErrorPage from 'components/ErrorPage';
import Header, { HeaderVariant } from 'shared/Header';
import CreatorLandingPage from 'components/CreatorLandingPage';
import MetaTagsHead from 'components/MetaTagsHead';
import { getUserAuth } from 'utils/server-auth';
import AccountPastDueAlert from 'components/AcccountPastDueAlert/AcccountPastDueAlert';
import { serverGetSessions, sessionQueryKeys } from '../../queries/sessions';

const IS_DEV = process.env.NEXT_PUBLIC_NODE_ENV !== 'production';

const DynamicCreatorSubscribedPage = dynamic(
  () => import('../../components/CreatorPage/SubscribedPage/CreatorSubscribed'),
  { ssr: false },
);

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const { creatorPage } = context.params as ParsedUrlQuery;
  const queryClient = new QueryClient();

  const page = creatorPage as string;
  const pageQuery = queryClient.prefetchQuery(['pageConfig', { page }], () =>
    getPage(page),
  );

  const sessionQuery = queryClient.prefetchQuery(
    sessionQueryKeys.all(page),
    () => serverGetSessions(context.req, page),
  );

  const data = getUserAuth(context);

  await Promise.all([pageQuery, sessionQuery]);

  console.log({
    state: dehydrate(queryClient).queries.map((val) => val.state),
  });

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      creatorPage: page,
      data,
    },
  };
};

const CreatorPage = ({
  data: { user },
  creatorPage,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const isSignedIn = !!user;
  const { data: metadata } = useUserMetadata(isSignedIn);
  const { data: pageConfig, error: pageConfigError } =
    usePageConfig(creatorPage);

  const [hasSubscription] = useMemo(
    () =>
      hasSubscriptionFn(
        metadata,
        pageConfig?.config.subscriptions || [],
        isSignedIn,
      ),
    [metadata, pageConfig, isSignedIn],
  );

  useEffect(() => {
    if (!IS_DEV) {
      // Note: do NOT pass generic default value to heap.identify call(s)
      if (user) {
        window.heap?.identify(user.id.toString());
      }
    }
  }, [user]);

  const loggedInAsPageCreator = pageConfig?.users.find(
    (pageUser) => pageUser.id === user?.id,
  );

  if (pageConfig) {
    return (
      <div>
        <MetaTagsHead pageConfig={pageConfig} />

        <Header variant={HeaderVariant.dark} user={user} />

        {user && (loggedInAsPageCreator || hasSubscription) ? (
          <DynamicCreatorSubscribedPage
            pageConfig={pageConfig}
            metadata={metadata as UserMetaDataResponse}
            user={user}
          />
        ) : (
          <CreatorLandingPage config={pageConfig} metadata={metadata} />
        )}

        <AccountPastDueAlert user={user} />
      </div>
    );
  }

  if (pageConfigError) {
    return (
      <>
        <Header variant={HeaderVariant.dark} user={user} />
        <ErrorPage status={500} />
      </>
    );
  }

  return (
    <>
      <Header variant={HeaderVariant.dark} user={user} />
      <Loading />
    </>
  );
};

export default CreatorPage;
