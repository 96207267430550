import { PageConfigResponse } from '@solin-fitness/types';
import Head from 'next/head';
import { buildImageUrl } from 'cloudinary-build-url';
import { RESIZE_TYPES } from '@cld-apis/utils';

type Props = {
  pageConfig?: PageConfigResponse;
};

const MetaTagsHead = ({ pageConfig }: Props) => {
  const adminUser = pageConfig?.users.find((u) => u.type === 2);

  const title = adminUser
    ? `${adminUser?.profile.firstName} ${adminUser?.profile.lastName} | Solin Stream`
    : !pageConfig
    ? 'Solin Stream'
    : `${pageConfig.config.pageName} | Solin Stream`;

  const metaImage = buildImageUrl(
    pageConfig?.config.backgroundImage1PublicId || '',
    {},
  );

  const appleTouchIcon = pageConfig?.config.pageLogoPublicId
    ? buildImageUrl(pageConfig?.config.pageLogoPublicId, {
        transformations: {
          resize: {
            // @ts-ignore - RESIZE_TYPES.LIMIT_PAD has wrong string value
            type: 'lpad',
            width: 512,
            height: 512,
          },
          background: 'rgb:ffffff',
          format: 'png',
        },
      })
    : undefined;

  return (
    <Head>
      <title>{title}</title>
      <meta
        property="og:title"
        content={pageConfig?.config.pageName}
        key="title"
      />
      <meta
        name="og:description"
        key="description"
        content={pageConfig?.config.pageDescription}
      />
      <meta property="og:image" key="image" content={metaImage} />
      <meta name="description" content={pageConfig?.config.pageDescription} />
      {appleTouchIcon ? (
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
      ) : null}
    </Head>
  );
};

export default MetaTagsHead;
